
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import MenuEntry from "@/components/member/MenuEntry.vue";
import BaseImgCircle from "@/components/BaseImgCircle.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";

export default defineComponent({
  name: "Profile",
  components: { BaseHeader2, MenuEntry, BaseImgCircle, DefaultButton },
  data() {
    const store = useStore();
    return {
      store,
      memberId: null as null | string,
      nickname: "",
      thumbnail: "",
      profile: {
        urgent: 0,
        board_post: 2,
        comment_count: 42,
        last_login_dttm: "",
        signup_dttm: "",
      },
    };
  },
  computed: {
    isAllowedModify(): boolean {
      const isLogin = this.store.getters["member/isLogin"];
      const member = this.store.getters["member/member"];
      if (isLogin) {
        if (this.memberId == member.id) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    showBlockMenu() {
      this.$flutter.callHandler("bottomAlert", {
        actions: JSON.stringify([
          {
            display: "차단하기",
            action: "showBlockMemberAlert",
            color: "ok",
          },
        ]),
      });
    },
    blockMember() {
      this.$axios.post("/member/block", { target: this.memberId }).then(() => {
        this.$router.go(-2);
      });
    },
    showBlockMemberAlert() {
      const isLogin = this.store.getters["member/isLogin"];
      if (!isLogin) {
        this.$flutter.callHandler("showToast", {
          message: "로그인 사용자만 차단할 수 있습니다.",
        });
        this.$router.push("/login/before?redirect=/profile");
        return;
      }
      this.$flutter.callHandler("alert", {
        title: "차단 후에는 해제할 수 없습니다.\n차단하시겠습니까?",
        message: "사용자를 차단하시면 사용자의 글과 댓글이 표시되지 않습니다.",
        actions: JSON.stringify([
          {
            display: "차단하기",
            action: "blockMember",
            parameter: "",
            color: "ok",
          },
          {
            display: "닫기",
            action: "nok",
          },
        ]),
      });
    },
  },
  beforeMount() {
    window.blockMember = this.blockMember;
    window.showBlockMemberAlert = this.showBlockMemberAlert;

    if (this.$route.query) {
      const memberData = this.$route.query as {
        member: string;
        nickname: string;
        thumbnail: string;
      };

      this.memberId = memberData.member;
      this.nickname = memberData.nickname;
      this.thumbnail = memberData.thumbnail;
      if (this.memberId) {
        this.$pushGAEvent("profile_view", {
          nickname: this.nickname,
        });

        this.$axios
          .get(`/member/profile`, { params: { id: this.memberId } })
          .then((res) => {
            const data = res.data as {
              urgent: number;
              board_post: number;
              comment_count: number;
              last_login_dttm: string;
              signup_dttm: string;
            };
            this.profile.urgent = data.urgent;
            this.profile.board_post = data.board_post;
            this.profile.comment_count = data.comment_count;
            this.profile.last_login_dttm = data.last_login_dttm;
            if (this.profile.last_login_dttm) {
              this.profile.last_login_dttm = this.$timeAgo(
                this.$convertToDate(this.profile.last_login_dttm)
              );
            }

            this.profile.signup_dttm = data.signup_dttm;
            if (this.profile.signup_dttm) {
              const tmpTime = this.profile.signup_dttm.split("-");
              this.profile.signup_dttm =
                tmpTime[0] + "년 " + tmpTime[1] + "월 " + tmpTime[2] + "일";
            }
          });
      }
    }
  },
  beforeUnmount() {
    window.blockMember = null;
    window.showBlockMemberAlert = null;
  },
});
